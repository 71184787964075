import React, { useEffect } from 'react';
import { navigate } from 'gatsby-link';
import { useIntl } from 'gatsby-plugin-intl';

import { useChapters } from '../hooks/useChapters';

const IndexPage = () => {
  const intl = useIntl();
  const chapters = useChapters();

  const [{ node: chapter }] = [...chapters].reverse();
  const [page] = [...chapter.pages].reverse();
  const latest = `/${intl.locale}/${page.chapter[0].slug}/${page.slug}`;

  useEffect(() => {
    navigate(latest);
  }, []);

  return <div className="w-screen h-screen bg-black"></div>;
};

export default IndexPage;
