import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

export const useChapters = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query PaginationQuery {
      chaptersInEnglish: allContentfulChapter(
        sort: { fields: createdAt, order: ASC }
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            slug
            node_locale
            title
            pages {
              slug
              title
              chapter {
                slug
              }
            }
          }
        }
      }
      chaptersInSpanish: allContentfulChapter(
        sort: { fields: createdAt, order: ASC }
        filter: { node_locale: { eq: "es-MX" } }
      ) {
        edges {
          node {
            slug
            node_locale
            title
            pages {
              slug
              title
              chapter {
                slug
              }
            }
          }
        }
      }
    }
  `);

  const { edges: chapters } = intl.locale === 'en' ? data.chaptersInEnglish : data.chaptersInSpanish;

  return [...chapters];
};
